

body {
	margin: 0;
	background: linear-gradient(45deg, #49a09d, #dfdfdf);
	font-family: sans-serif;
	font-weight: 100;
  height: 100%;
  background-attachment: fixed;
  
}
.shadow {
  border: 2px solid rgba(255,197,145,0.75);
  border-radius: 10px;
  box-shadow: 1px 2px 5px 0px rgba(255,255,255,0.75) !important;
  -webkit-box-shadow: 1px 2px 5px 0px rgba(255,255,255,0.75) !important;
  -moz-box-shadow: 1px 2px 5px 0px rgba(255,255,255,0.75) !important;





}

.my-table {
    width: 1275px;
    border-collapse: collapse;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  
  .my-table th,
  .my-table td {
    padding: 15px;
    background-color: rgba(255, 255, 255);
    color: #000000;
    border: 1px solid #000000;

  }
  
  .my-table th {
    text-align: left;
  }
  
  .my-table thead th {
    background-color: #3c5b94;
  }
  
  .my-table tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
  
  .my-table td:hover:before {
    
    position: absolute;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.2);
    z-index: -1;
  }

.Busqueda{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.Encabezado{
  text-align: center;
}

h1{
  color: white;
}

  
@media print{


.unshow{
  display: none;  
}

  
.printTable{
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff !important;
}

}
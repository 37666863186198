.formulario-container {
    display: flex;
    justify-content: space-between;
  }
  
  .formulario-column {
    display: flex;
    flex-direction: column;
  }
  
  .formulario {
    width: 300px;
    margin-bottom: 20px;
    display: inline-block;
  }
  
  .formulario-buttons {
    margin-top: 20px;
  }
  
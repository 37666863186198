

body {
	margin: 0;
	background: linear-gradient(45deg, #49a09d, #1d3563);
	font-family: sans-serif;
	font-weight: 100;
  height: 100%;
  background-attachment: fixed;
  
}
.shadow {
  border: 5px solid rgba(255,197,145,0.75);
  border-radius: 10px;
  box-shadow: 1px 2px 14px 0px rgba(255,255,255,0.75) !important;
  -webkit-box-shadow: 1px 2px 14px 0px rgba(255,255,255,0.75) !important;
  -moz-box-shadow: 1px 2px 14px 0px rgba(255,255,255,0.75) !important;





}

.my-table-secondary {
    width: 975px;
    border-collapse: collapse;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  
  }
  
  .my-table-secondary th,
  .my-table-secondary td {
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
  }
  
  .my-table-secondary th {
    text-align: left;
  }
  
  .my-table-secondary thead th {
    background-color: #3c5b94;
  }
  
  .my-table-secondary tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
  
  .my-table-secondary td:hover:before {
    
    position: absolute;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.2);
    z-index: -1;
  }

.Busqueda{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.Encabezado{
  text-align: center;
}

h1{
  color: white;
}

.custom-table {
  font-size: 12px;
  
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  border-color: black;
 
}
.boxShadow {
  box-shadow: 19px 22px 5px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: 19px 22px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 19px 22px 5px 0px rgba(0,0,0,0.75);

}

@media print{
  .unshow{
    display: none;  
  }
  .boxShadow {
    box-shadow: 19px 22px 5px 0px rgba(255, 255, 255, 0.75);
    -webkit-box-shadow: 19px 22px 5px 0px rgba(255, 255, 255, 0.75);
    -moz-box-shadow: 19px 22px 5px 0px rgba(255, 255, 255, 0.75);
  }
}

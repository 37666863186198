
.social-btns .btn,
.social-btns .btn:before,
.social-btns .btn .btn-save {
  transition: all 1.5s;
  transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
}
.social-btns .btn:before {
  top: 90%;
  left: -110%;
}
.social-btns .btn .btn-save {
  transform: scale(0.6);
}

.social-btns .btn.save:before {
    background-color: #3b5998;
  }
  .social-btns .btn-save {
    color: #3b5998;
  }

.social-btns .btn:focus:before,
.social-btns .btn:hover:before {
  top: -60%;
  left: 0%;
}

.social-btns .btn:focus .btn-save,
.social-btns .btn:hover .btn-save {
  color: #fff;
  transform: scale(10000);
}
.social-btns {
  height: 150px;
  margin: auto;
  font-size: 0;
  text-align: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.social-btns .btn {
  display: inline-block;
  background-color: #fff;
  width: 140px;
  height: 40px;
  line-height: 20px;
  margin: 0 10px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 7%;
  box-shadow: 0 5px 15px -5px rgba(0,0,0,0.1);
  opacity: 0.99;
}
.social-btns .btn:before {
  content: '';
  width: 120%;
  height: 300%;
  position: absolute;
  transform: rotate(45deg);
}
.social-btns .btn .btn-save{
  font-size: 25px;
  vertical-align: middle;
}



@import url(https://fonts.googleapis.com/css?family=Raleway);

* {
	margin: 0;
	padding: 0;
	-webkit-transition: all .3s;
	-moz-transition: all .3s;
	-ms-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
}

select {
   width: 300px;
   height: 50px;
   border-radius: 10px;
   padding-left: 10px;
  }
  
  select:hover {
    background-color: transparent;
    color: white;
    padding: 15px 15px 15px 25px;
  }
  
   select option {
    background-color: white;
    color: black;
    width: 410px;
    padding: 10px 15px;
    height: 50px;
    cursor: pointer;
    
  }
  select option:hover{
    top: 120px;
    background-color: aqua;
  }
:root {
  --font: "Roboto", sans-serif;
  --fontOrigin: 1rem; /* 16px */
  --fontSmall: 0.625em; /* 10px */
  --fontMedium: 0.875em; /* 14px */
  --fontNormal: 1em; /* 16px */
  --fontLarge: 1.375em; /* 22px */
  --fontLight: 100;
  --fontRegular: 400;
  --speed: 0.65s;
}

@keyframes zoom {
  from {
    object-position: 0 50%;
  }
  to {
    object-position: 100% 50%;
  }
}

@keyframes blur {
  from {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  to {
    -webkit-transform: scale(1.5, 1.5);
    transform: scale(2, 2);
  }
}


.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, 1fr);
  grid-template-rows: 1;
  grid-template-areas:".card.";
  width: 50vw;
  min-height: 50vh;
  padding-top: 40px;
  padding-bottom: 40px;
  
}

.card {
  position: grid;
  grid-area: card;
  align-self: center;
  justify-self: left;
  overflow: hidden;
  display: block;
  width: 100%;
  max-width: 250px;
  height: 400px;
  font-size: var(--fontOrigin);
  border-radius: 8px;
  box-shadow: 0 16px 60px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  -webkit-transition: all var(--speed) ease;
  transition: all var(--speed) ease;
  background-color: #3c5b94;
}

.card:hover {
  box-shadow: 0 40px 130px rgba(0, 0, 0, 0.6);
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}

.card__image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5));
}

.card__image img {
  -webkit-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  -webkit-transition: all var(--speed) ease;
  transition: all var(--speed) ease;
  animation-name: zoom;
  animation-duration: 30s;
  animation-direction: alternate;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.card:hover .card__image img {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  opacity: 0.5;
}

.card__header {
  display: grid;
  width: 100%;
  height: 200px;
}

.card__profile {
  align-self: center;
  justify-self: center;
  display: block;
  overflow: hidden;
  width: 10vmax;
  height: 10vmax;
  max-width: 100px;
  max-height: 100px;
  border-radius: 50%;
}

.card__profile img {
  -webkit-transform: scale(1.5, 1.5) translateZ(0);
  transform: scale(1.5, 1.5) translateZ(0);
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  -webkit-filter: grayscale(50%) contrast(75%) brightness(1.3);
  filter: grayscale(50%) contrast(75%) brightness(1.3);
  -webkit-transition: all var(--speed) ease;
  transition: all var(--speed) ease;
  mix-blend-mode: normal;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card:hover .card__profile img {
  -webkit-transform: scale(1, 1) translateZ(0);
  transform: scale(1, 1) translateZ(0);
}

.card__body {
  display: grid;
  padding: 0 20px;
}

.card__name {
  align-self: center;
  justify-self: center;
  margin-bottom: 2px;
  color: white;
  font-size: var(--fontLarge);
  font-weight: var(--fontLight);
  letter-spacing: 0.1rem;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.35);
  background: -webkit-linear-gradient(white, #a1a1a1);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-transition: all var(--speed) ease;
  transition: all var(--speed) ease;
}

.card:hover .card__name {
  background: -webkit-linear-gradient(white, #c1c1c1);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card__bio {
  position: relative;
  -webkit-transform: translateY(30%);
  transform: translateY(30%);
  display: block;
  margin: 22px 0 16px 0;
  font-size: var(--fontMedium);
  font-weight: var(--fontLight);
  /*text-shadow: 0 0.5px 1px rgba(0, 0, 0, 0.35);*/
  color: rgba(255, 255, 255, 0.65);
  opacity: 0;
  -webkit-transition: all var(--speed) ease;
  transition: all var(--speed) ease;
}

.card:hover .card__bio {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.div_card
{
  display: flex;
  justify-content: center;
  margin-left: 50px;
}


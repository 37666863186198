.navbar{
	background: #3c5b94;
	box-shadow: 5px 5px 5px rgb(68, 68, 68);
}

.navbar.Brand{
	margin-left: 0;
	margin-right: auto;
}

.navbar.Toggle{
	margin: auto;
}


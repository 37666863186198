.favoritos{
	width: 70%;
	height: 100%;
	background: transparent;
	border: 1px solid black;
	justify-content: center;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: auto;
	padding: 15px 5px 15px 25px;
	box-shadow: 5px 5px 5px 5px black;
	border-radius: 10px;
	opacity: 75%;
}

p{
	color: white;
	font-size: x-large;
}

.seleccion{
	float: left;
	margin-top: 50px;
	align-items: left;
	width: 500px;
	margin-top: 5px;
}

